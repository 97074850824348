import { Button } from '@material-ui/core';


const Pagination = ({ postsPerPage, totalPosts, nextButton, previousButton, prevClass, nextClass }) => {
    const pageNumbers = [];

    // for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    //     pageNumbers.push(i);
    // }

    return (
        // <nav>
        //     <ul className='pagination'>
        //         {pageNumbers.map(number => (
        //             <li key={number} className='page-item'>
        //                 <a onClick={() => paginate(number)} href='!#' className='page-link'>
        //                     {number}
        //                 </a>
        //             </li>
        //         ))}
        //     </ul>
        // </nav>
        <>
            <div className="btn-pre-view">
                <button type="button" className={`prev-btn-now ${prevClass && "disabled_button"}`} disabled={prevClass} onClick={() => previousButton()}>Previous</button>
                <button type="button" className={`prev-btn-now Next-btn ${nextClass && "disabled_button"}`} disabled={nextClass} onClick={() => nextButton()}>Next</button>
            </div>

        </>
    );
};

export default Pagination;